import { MODAL_TYPE_CREATE_POST } from '../components/modals/post-create-modal/post-create-modal-type';
import createAction from '../../common/services/create-action';
import sanitizeContent from '../services/sanitize-content';
import { extractHashtagsFromContent } from '@wix/communities-forum-client-commons/dist/src/services/hashtag-utils';
import { extractMentionsFromContent } from '@wix/communities-forum-client-commons/dist/src/services/mentions-services';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { getCategory } from '../../common/selectors/categories-selectors';
import { closeModal } from '../../common/modals/framework/store/modal-actions';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { isExternalHookResolve } from '../../common/services/external-hooks';
import { createExternalHookRejectHandler } from '../../common/services/external-hooks-flow';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import {
  EXPERIMENT_CREATE_POST_CAPTCHA,
  EXPERIMENT_CREATE_POST_CAPTCHA_BY_SPAMMED_FLAG,
  EXPERIMENT_ENABLE_CAPTCHA_SETTINGS,
  EXPERIMENT_FORCE_CAPTCHA_BY_CONTENT,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { getIsForumSpammed, getCaptchaSettings } from '../selectors/forum-data-selectors';
import { userEventsPostCaptchaResolved, userEventsPostCaptchaShown } from './user-events';
import { getRichContentFeatures } from '@wix/rich-content-features';
import { hasSuspiciousFields, textToDraftContent } from '../../common/services/captcha-helpers';

export const CREATE_POST_REQUEST = 'post/CREATE_REQUEST';
export const CREATE_POST_SUCCESS = 'post/CREATE_SUCCESS';
export const CREATE_POST_FAILURE = 'post/CREATE_FAILURE';

export const createPostRequest = createAction(CREATE_POST_REQUEST);
export const createPostSuccess = createAction(CREATE_POST_SUCCESS);
export const createPostFailure = createAction(
  CREATE_POST_FAILURE,
  () => undefined,
  meta => meta,
);

export function createPost(post = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(createPostRequest());
    const postSanitized = sanitizeContent(post);
    const promise = request.post(
      `/posts/`,
      {
        ...postSanitized,
        hashtags: extractHashtagsFromContent(postSanitized.content),
        mentions: extractMentionsFromContent(postSanitized.content),
      },
      { noRetriesOnErrors: [503, 504] },
    );

    return promise
      .then(
        post => dispatch(createPostSuccess(post)),
        response => dispatch(createPostFailure(response)),
      )
      .then(() => promise);
  };
}

export const createPostAndNavigateToItPromisified = onBeforePostCreateHook =>
  createPromisifiedAction(
    postRaw => {
      return async (dispatch, getState, { wixCodeApi }) => {
        const {
          enableOnPostCreation: isCaptchaOnPostEnabled,
          enableOnSuspiciousActivity: isCaptchaOnSuspiciousEnabled,
        } = getCaptchaSettings(getState());

        const isCaptchaExperimentEnabled = isExperimentEnabled(
          getState(),
          EXPERIMENT_CREATE_POST_CAPTCHA,
        );
        const isCaptchaByFlagExperimentEnabled = isExperimentEnabled(
          getState(),
          EXPERIMENT_CREATE_POST_CAPTCHA_BY_SPAMMED_FLAG,
        );
        const isCaptchaSettingsExperimentEnabled = isExperimentEnabled(
          getState(),
          EXPERIMENT_ENABLE_CAPTCHA_SETTINGS,
        );
        const isCaptchaByContentForced = isExperimentEnabled(
          getState(),
          EXPERIMENT_FORCE_CAPTCHA_BY_CONTENT,
        );

        const isForumSpammed = getIsForumSpammed(getState());

        let isCaptchaForContent = false;
        const isCaptchaForced = isCaptchaExperimentEnabled;
        const isCaptchaForSpammedForum = isCaptchaByFlagExperimentEnabled && isForumSpammed;

        if (isCaptchaByContentForced || isCaptchaOnSuspiciousEnabled) {
          const contentFeatures = getRichContentFeatures(postRaw.content);
          const titleFeatures = getRichContentFeatures(textToDraftContent(postRaw.title));

          isCaptchaForContent =
            hasSuspiciousFields(contentFeatures) || hasSuspiciousFields(titleFeatures);
        }

        if (
          isCaptchaForced ||
          isCaptchaForSpammedForum ||
          (isCaptchaSettingsExperimentEnabled && (isCaptchaForContent || isCaptchaOnPostEnabled)) ||
          // Temporary, should be removed, when decision on default captcha settings is made
          (isCaptchaByContentForced && isCaptchaForContent)
        ) {
          dispatch(userEventsPostCaptchaShown());
          const token = await wixCodeApi.authentication.openCaptchaChallenge();
          dispatch(userEventsPostCaptchaResolved(!!token));

          if (!token) {
            return Promise.resolve({
              isSuccessful: false,
            });
          }
        }

        let promise = Promise.resolve();
        if (onBeforePostCreateHook.hasHook()) {
          promise = promise.then(() =>
            onBeforePostCreateHook.exec(postRaw).catch(createExternalHookRejectHandler(dispatch)),
          );
        }

        return promise
          .then(r =>
            dispatch(createPost(isExternalHookResolve(r) ? r.payload || postRaw : postRaw)),
          )
          .then(post => {
            const category = getCategory(getState(), post.categoryId);
            dispatch(closeModal({ type: MODAL_TYPE_CREATE_POST, resolve: false }));
            dispatch(navigateWithinForum(`/${category.slug}/${post.slug}`));
          })
          .catch(response => ({
            isSuccessful: false,
            status: response.status,
          }));
      };
    },
    ({ isSuccessful = true, status } = {}) => ({ isSuccessful, status }),
  );
