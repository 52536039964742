import createAction from '../services/create-action';
import { getTranslations } from '../store/translations/translations-selectors';
import { setTranslations } from '../store/translations/translations-actions';
import abTranslate from 'ab-translate/dist/src/abTranslate-runtime';
import { EXPERIMENT_WIX_COMMENTS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { isEditor } from '../store/basic-params/basic-params-selectors';

export const FETCH_EXPERIMENTS_REQUEST = 'experiments/FETCH_EXPERIMENTS_REQUEST';
export const FETCH_EXPERIMENTS_SUCCESS = 'experiments/FETCH_EXPERIMENTS_SUCCESS';
export const FETCH_EXPERIMENTS_FAILURE = 'experiments/FETCH_EXPERIMENTS_FAILURE';

export const fetchExperimentsRequest = createAction(FETCH_EXPERIMENTS_REQUEST);
export const fetchExperimentsSuccess = createAction(FETCH_EXPERIMENTS_SUCCESS);
export const fetchExperimentsFailure = createAction(FETCH_EXPERIMENTS_FAILURE);

export function fetchExperiments() {
  return (dispatch, getState, { request, wixCommentsExperimentApi }) => {
    dispatch(fetchExperimentsRequest());

    const promise = request('/experiments');

    if (!isEditor(getState()) && wixCommentsExperimentApi) {
      wixCommentsExperimentApi('/enabled').then(isEnabled => {
        dispatch(fetchExperimentsSuccess({ [EXPERIMENT_WIX_COMMENTS]: `${isEnabled}` }));
      });
    }

    return promise.then(
      body => {
        const currentTranslations = getTranslations(getState());
        const translationsWithExperiments = abTranslate(body, currentTranslations);
        dispatch(setTranslations(translationsWithExperiments));
        return dispatch(fetchExperimentsSuccess(body));
      },
      () => dispatch(fetchExperimentsFailure()),
    );
  };
}
