
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript.js';
  
  
            import controller0 from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/components/Forum/controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/components/Forum/controller.ts';
            var controllerExport0 = _controllerExport0;

            import controller1 from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/components/RecentPostsFeed/controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/components/RecentPostsFeed/controller.ts';
            var controllerExport1 = _controllerExport1;

  
  import * as viewerApp from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/viewer.app.ts';
    var importedApp = viewerApp;


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  
var initI18n = null;

  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    


  var sentryConfig = {
      DSN: 'https://baf30a2b91654c5a840931f0137bed30@sentry.wixpress.com/1696',
      id: 'baf30a2b91654c5a840931f0137bed30',
      projectName: 'communities-forum-client',
      teamName: 'forum',
      
    };

  var experimentsConfig = {"scopes":["wix-forum"]};

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var defaultTranslations = null;

  var fedopsConfig = {"enabled":false};

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "communities-forum-client",
    biConfig: null,
    appName: "communities-forum-client",
    appDefinitionId: "14724f35-6794-cd1a-0244-25fd138f9242",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: false,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  export const createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
  }, [{ method: controller0,
          exports: controllerExport0,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scopes":["wix-forum"]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          biLogger: biLogger,
          shouldUseEssentials: false,
          withErrorBoundary: true,
          biConfig: null,
          controllerFileName: "/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/components/Forum/controller.ts",
          appName: "communities-forum-client",
          appDefinitionId: "14724f35-6794-cd1a-0244-25fd138f9242",
          projectName: "communities-forum-client",
          componentName: "Forum",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "1489040e-001f-4631-55a9-2c29b4417126" }, { method: controller1,
          exports: controllerExport1,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scopes":["wix-forum"]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          biLogger: biLogger,
          shouldUseEssentials: false,
          withErrorBoundary: true,
          biConfig: null,
          controllerFileName: "/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/components/RecentPostsFeed/controller.ts",
          appName: "communities-forum-client",
          appDefinitionId: "14724f35-6794-cd1a-0244-25fd138f9242",
          projectName: "communities-forum-client",
          componentName: "RecentPostsFeed",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "151bd179-dfe6-3ca3-399c-ee97957f93a2" }], true);
