import { identity } from 'lodash';
import { getInstanceValue } from '../../common/store/instance-values/instance-values-selectors';
import { isDummyVisitorId } from '../bi-events/bi-helpers';

const log = (...message) => {
  process.env.NODE_ENV === 'development' && console.error(...message);
};

const tryTransformEvent = transformEvent => (
  action,
  state,
  vsi,
  config,
  biLocation,
  platformBiProperties,
) => {
  try {
    return transformEvent(action, state, vsi, config, biLocation, platformBiProperties);
  } catch (e) {
    log('BI: event transformation failed', e, action, state);
  }
};

const tryLogEvent = event => bi => {
  const promise = bi.log(event);
  promise.catch(e => {
    log('BI: external bi error', e, event);
  });
  return promise;
};

const createBiMiddleware = ({
  bi,
  eventMap,
  visitorId,
  viewerSessionId = '',
  config,
  BiLocationTracker,
  wixCodeApi,
  platformBiProperties,
}) => {
  const maybeLog = (action, state) => {
    const transformEvent = eventMap[action.type];

    if (!transformEvent) {
      return identity;
    }

    const biEvent = tryTransformEvent(transformEvent)(
      action,
      state,
      viewerSessionId,
      config,
      BiLocationTracker,
      platformBiProperties,
    ); // event => bi event
    if (!biEvent) {
      return identity;
    }
    biEvent.member_id = (state.currentUser || {}).siteMemberId;

    if (wixCodeApi.window.viewMode !== 'Site') {
      biEvent._viewMode = wixCodeApi.window.viewMode.toLowerCase();
    }
    return tryLogEvent(biEvent);
  };

  return store => next => action => {
    const state = store.getState();
    if (!isDummyVisitorId(visitorId || getInstanceValue(state, 'uid'))) {
      maybeLog(action, state)(bi);
    }

    return next(action);
  };
};

export default createBiMiddleware;
