import { get } from 'lodash';

export const getForumData = state => state.forumData;
export const getCommunityId = state => getForumData(state).communityId;
export const getInstanceId = state => getForumData(state)._id;
export const getForumTitle = state => getForumData(state).label;
export const getIsForumHot = state => getForumData(state).isHot;
export const getPostLimiter = state => getForumData(state).newPostLimiter;
export const getAttachmentSizeLimit = state => getForumData(state).attachmentSizeLimit;
export const getCaptchaSettings = state => getForumData(state).captchaSettings || {};

export const getIsRegistrationWithApproval = state =>
  getForumData(state).registrationManuallyApproved;

export const getRegistrationIsOpen = state => getForumData(state).registrationOpened;

export const getIsStorageLimitReached = state =>
  get(getForumData(state), 'mediaQuota.storageExceeded', false);

export const getIsVideoDurationLimitReached = state =>
  get(getForumData(state), 'mediaQuota.videoDurationExceeded', false);

export const getIsForumSpammed = state => getForumData(state).isForumSpammed;
