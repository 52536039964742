import { cloneDeep } from 'lodash';
import { MODAL_TYPE_CATEGORIES_MANAGER } from '../components/modals/categories-manager/categories-manager-modal-type';
import { MODAL_TYPE_CATEGORIES_MANAGER_CONFIRM_DISCARD } from '../components/modals/categories-manager/discard-changes-modal-type';
import {
  TEMPLATE_CATEGORY_ID,
  FORUM_DATA_CATEGORIES_MANAGER_ID,
  TEMPLATE_CATEGORY_GROUP_ID,
} from '@wix/communities-forum-client-commons/dist/src/constants/categories-manager';
import createAction from '../../common/services/create-action';
import { createCategory } from './create-category';
import { updateCategory } from './update-category';
import { updateForumData } from './update-forum-data';
import { openModal, closeModal } from '../../common/modals/framework/store/modal-actions';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import * as messages from '../components/messages/message-types';
import {
  getHighestRank,
  getCategory,
  getCategories,
} from '../../common/selectors/categories-selectors';
import {
  isNewCategory,
  isCategoryEdited,
  getCategoryInEditId,
  getInitialCopy,
  isForumDataEdited,
  isCategoryFormEdited,
  isPersistedCategory,
} from '../selectors/categories-manager-selectors';
import {
  omitProhibitedUpdateFields,
  createCategoryTemplate,
  decorateCategoryWithType,
} from '../services/categories-service';
import { getForumData } from '../selectors/forum-data-selectors';
import { updateCategoryRanks } from './update-category-ranks';
import { updateCategories } from './update-categories';
import { fetchAllCategories, fetchCategoriesSuccess } from '../../common/actions/fetch-categories';
import {
  userEventsEditCategory,
  userEventsCreateCategory,
  userEventsDiscardChangesCategory,
  userEventsMakeCategoryChildOrParent,
} from './user-events';
import {
  CATEGORIES_MANAGER_OPEN_MAIN_WINDOW,
  CATEGORIES_MANAGER_EDIT_CATEGORY,
  CATEGORIES_MANAGER_CREATE_CATEGORY,
  CATEGORIES_MANAGER_DELETE_CATEGORY,
  CATEGORIES_MANAGER_UPDATE_CATEGORY,
  CATEGORIES_MANAGER_UPDATE_FORUM_DATA,
  CATEGORIES_MANAGER_SHOW_LOADER,
  CATEGORIES_MANAGER_HIDE_LOADER,
  CATEGORIES_MANAGER_FLOW_TYPE_EDIT,
  CATEGORIES_MANAGER_FLOW_TYPE_CREATE,
} from './categories-manager-actions-constants';
import { CREATE_CATEGORY, UPDATE_CATEGORY } from '../constants/interactions';

import {
  getListOfSiteMembersToGiveAccessTo,
  getListOfSiteMembersToRemoveAccessFrom,
} from '../selectors/manage-category-members-selectors';
import { assignMembersToGroup } from './assign-members-to-group';
import { deleteMembersFromGroup } from './delete-members-from-group';
import {
  resetSiteMembersList,
  fetchSiteMembersList,
} from '../../common/store/site-members/site-members-actions';
import { fetchMembersGroupsList } from './fetch-members-groups-list';
import { fetchCategory } from './fetch-category';
import { deleteCategory } from './delete-category';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { MANAGE_GENERAL_INFO, MANAGE_MEMBERS } from '../constants/categories-manager-section-types';

export const categoriesManagerOpenMainWindow = createAction(CATEGORIES_MANAGER_OPEN_MAIN_WINDOW);
export const categoriesManagerEdit = createAction(CATEGORIES_MANAGER_EDIT_CATEGORY);
export const categoriesManagerCreateCategory = createAction(
  CATEGORIES_MANAGER_CREATE_CATEGORY,
  payload => payload,
  (payload, meta) => meta,
);
export const categoriesManagerDeleteCategory = createAction(
  CATEGORIES_MANAGER_DELETE_CATEGORY,
  payload => payload,
  (payload, meta) => meta,
);
export const categoriesManagerUpdateCategory = createAction(
  CATEGORIES_MANAGER_UPDATE_CATEGORY,
  payload => payload,
  (payload, meta) => meta,
);
export const categoriesManagerUpdateForumData = createAction(
  CATEGORIES_MANAGER_UPDATE_FORUM_DATA,
  payload => payload,
  (payload, meta) => meta,
);
export const categoriesManagerShowLoader = createAction(
  CATEGORIES_MANAGER_SHOW_LOADER,
  payload => payload,
  (payload, meta) => meta,
);
export const categoriesManagerHideLoader = createAction(
  CATEGORIES_MANAGER_HIDE_LOADER,
  payload => payload,
  (payload, meta) => meta,
);

const fetchMemberAccessData = dispatch => {
  // we display groups counter in category form, need this data
  dispatch(fetchMembersGroupsList());
  dispatch(resetSiteMembersList());
  dispatch(fetchSiteMembersList({ query: '', offset: 0 })); // this is so we can show total count of site members
};

export const categoriesManagerEditCategory = (
  categoryId,
  openPanels = [MANAGE_MEMBERS, MANAGE_GENERAL_INFO],
  flowType = CATEGORIES_MANAGER_FLOW_TYPE_EDIT,
) => (dispatch, getState) => {
  const categoryCopy = cloneDeep(getCategory(getState(), categoryId));

  fetchMemberAccessData(dispatch);
  return dispatch(
    categoriesManagerEdit({
      categoryInEditId: categoryId,
      categoryCopy,
      openPanels,
      flowType,
    }),
  );
};

export const categoriesManagerEditForumData = openPanels => (dispatch, getState) => {
  const categoryCopy = cloneDeep(getForumData(getState()));
  return dispatch(
    categoriesManagerEdit({
      categoryInEditId: FORUM_DATA_CATEGORIES_MANAGER_ID,
      categoryCopy,
      openPanels,
    }),
  );
};

export const fetchAllCategoriesForModalAndApp = () => dispatch =>
  dispatch(fetchAllCategories()).then(categories => {
    dispatch(fetchCategoriesSuccess(categories));
  });

export const categoriesManagerUpdateForumDataLocally = (data, replace = false) => (
  dispatch,
  getState,
) => {
  const state = getState();
  let updatedData;

  if (replace) {
    updatedData = data;
  } else {
    const forumData = getForumData(state);
    updatedData = { ...forumData, ...data };
  }

  dispatch(categoriesManagerUpdateForumData(updatedData));
};

export const categoriesManagerUpdateCategoryLocally = (data, replace = false) => (
  dispatch,
  getState,
) => {
  let updatedCategory;

  if (replace) {
    updatedCategory = data;
  } else {
    const state = getState();
    const categoryId = getCategoryInEditId(state);
    const category = getCategory(state, categoryId);
    updatedCategory = { ...category, ...data };
  }
  dispatch(categoriesManagerUpdateCategory(updatedCategory, replace));
};

const createCategoryLocallyInApp = category => dispatch =>
  dispatch(categoriesManagerCreateCategory(category));

export const categoriesManagerCreateCategoryLocally = ({ label, description }) => (
  dispatch,
  getState,
) => {
  const category = createCategoryTemplate({
    label,
    description,
    rank: getHighestRank(getState()) + 1,
  });

  dispatch(createCategoryLocallyInApp(category));
  dispatch(
    categoriesManagerEditCategory(
      category._id,
      [MANAGE_MEMBERS, MANAGE_GENERAL_INFO],
      CATEGORIES_MANAGER_FLOW_TYPE_CREATE,
    ),
  );
};

const deleteCategoryTemplate = () => dispatch => {
  dispatch(categoriesManagerDeleteCategory(TEMPLATE_CATEGORY_ID));
};

const closeEditCategoryForm = shouldCloseManager =>
  shouldCloseManager
    ? closeModal({ type: MODAL_TYPE_CATEGORIES_MANAGER })
    : categoriesManagerOpenMainWindow();

export const categoriesManagerPersistCategory = () => (dispatch, getState) => {
  const state = getState();

  const listOfSiteMembersToGiveAccessTo = getListOfSiteMembersToGiveAccessTo(state);
  const listOfSiteMembersToRemoveAccessFrom = getListOfSiteMembersToRemoveAccessFrom(state);

  const updateCategoryAccess = category => {
    if (listOfSiteMembersToGiveAccessTo.length > 0) {
      dispatch(assignMembersToGroup(category.categoryGroupId, listOfSiteMembersToGiveAccessTo));
    }
    if (listOfSiteMembersToRemoveAccessFrom.length > 0) {
      dispatch(
        deleteMembersFromGroup(category.categoryGroupId, listOfSiteMembersToRemoveAccessFrom),
      );
    }
  };

  if (isNewCategory(state)) {
    // fedopsLogger.interactionStarted(CREATE_CATEGORY);
    const category = omitProhibitedUpdateFields(getCategory(state, TEMPLATE_CATEGORY_ID));
    return dispatch(createCategory(category)).then(category => {
      updateCategoryAccess(category);

      // fedopsLogger.interactionEnded(CREATE_CATEGORY);
      // const wasSingleCategory = getCategories(state).length === 2;
      // if (wasSingleCategory) {
      //   it was single category we want to trigger nav to home, so it shows both categories
      // getRouter().match('/');
      // }
      // navigate();
      dispatch(createCategoryLocallyInApp(category));
      dispatch(
        categoriesManagerEditCategory(
          category._id,
          [MANAGE_MEMBERS, MANAGE_GENERAL_INFO],
          CATEGORIES_MANAGER_FLOW_TYPE_CREATE,
        ),
      );
      dispatch(deleteCategoryTemplate());
      // dispatch(showMessage(messages.CATEGORY_CREATED));
      // dispatch(userEventsCreateCategory({ origin: 'categoriesManager', category }));
      return category;
    });
  }
  return Promise.resolve(getInitialCopy(state));
};

export const categoriesManagerPersistCategoryPromisified = createPromisifiedAction(
  categoriesManagerPersistCategory,
  category => ({ _id: category._id }),
);

export const categoriesManagerSaveChanges = () => (
  dispatch,
  getState,
  { fedopsLogger, getRouter },
) => {
  const state = getState();
  const navigate = () => dispatch(categoriesManagerOpenMainWindow());
  const initialCopy = getInitialCopy(state);

  const listOfSiteMembersToGiveAccessTo = getListOfSiteMembersToGiveAccessTo(state);
  const listOfSiteMembersToRemoveAccessFrom = getListOfSiteMembersToRemoveAccessFrom(state);

  const updateCategoryAccess = category => {
    if (listOfSiteMembersToGiveAccessTo.length > 0) {
      dispatch(assignMembersToGroup(category.categoryGroupId, listOfSiteMembersToGiveAccessTo));
    }
    if (listOfSiteMembersToRemoveAccessFrom.length > 0) {
      dispatch(
        deleteMembersFromGroup(category.categoryGroupId, listOfSiteMembersToRemoveAccessFrom),
      );
    }
  };

  if (isNewCategory(state)) {
    fedopsLogger.interactionStarted(CREATE_CATEGORY);
    const category = omitProhibitedUpdateFields(getCategory(state, TEMPLATE_CATEGORY_ID));
    return dispatch(createCategory(category)).then(category => {
      dispatch(userEventsCreateCategory({ origin: 'categoriesManager', category }));
      updateCategoryAccess(category);

      fedopsLogger.interactionEnded(CREATE_CATEGORY);
      const wasSingleCategory = getCategories(state).length === 2;
      if (wasSingleCategory) {
        // it was single category we want to trigger nav to home, so it shows both categories
        getRouter().match('/');
      }
      navigate();
      dispatch(deleteCategoryTemplate());
      dispatch(createCategoryLocallyInApp(category));
      dispatch(showMessage(messages.CATEGORY_CREATED));
    });
  } else if (isPersistedCategory(state)) {
    fedopsLogger.interactionStarted(CREATE_CATEGORY);
    const categoryId = getCategoryInEditId(state);
    const category = getCategory(state, categoryId);

    if (category.categoryGroupId === TEMPLATE_CATEGORY_GROUP_ID) {
      delete category.categoryGroupId;
    }

    return dispatch(updateCategory(categoryId, category))
      .then(category => {
        updateCategoryAccess(category);
        dispatch(categoriesManagerUpdateCategoryLocally(category, true));
      })
      .then(() => fedopsLogger.interactionEnded(CREATE_CATEGORY))
      .then(() => dispatch(fetchAllCategories()))
      .then(navigate)
      .then(() => dispatch(showMessage(messages.CATEGORY_CREATED)))
      .then(() => dispatch(userEventsCreateCategory({ origin: 'categoriesManager', category })));
  } else if (
    isCategoryEdited(state) ||
    listOfSiteMembersToGiveAccessTo.length > 0 ||
    listOfSiteMembersToRemoveAccessFrom.length > 0
  ) {
    fedopsLogger.interactionStarted(UPDATE_CATEGORY);
    const categoryId = getCategoryInEditId(state);
    const category = getCategory(state, categoryId);

    if (category.categoryGroupId === TEMPLATE_CATEGORY_GROUP_ID) {
      delete category.categoryGroupId;
    }

    return dispatch(updateCategory(categoryId, category))
      .then(category => {
        dispatch(
          userEventsEditCategory({
            origin: 'categoriesManager',
            categoryId,
            isChanged: true,
            initialCopy,
          }),
        );
        updateCategoryAccess(category);
        dispatch(categoriesManagerUpdateCategoryLocally(category, true));
      })
      .then(() => fedopsLogger.interactionEnded(UPDATE_CATEGORY))
      .then(() => dispatch(fetchAllCategories()))
      .then(navigate)
      .then(() => dispatch(showMessage(messages.CATEGORY_UPDATE)));
  } else {
    return Promise.resolve(navigate()).then(() =>
      dispatch(
        userEventsEditCategory({
          origin: 'categoriesManager',
          categoryId: getCategoryInEditId(state),
          isChanged: false,
          initialCopy,
        }),
      ),
    );
  }
};

export const categoriesManagerCancelChanges = (shouldCloseManager = false) => (
  dispatch,
  getState,
) => {
  const state = getState();

  if (isCategoryFormEdited(state)) {
    return dispatch(
      openModal(MODAL_TYPE_CATEGORIES_MANAGER_CONFIRM_DISCARD, { shouldCloseManager }),
    );
  }
  if (isPersistedCategory(state)) {
    const categoryId = getCategoryInEditId(state);
    dispatch(deleteCategory(categoryId));
  }
  dispatch(userEventsDiscardChangesCategory({ isChanged: false }));
  dispatch(closeEditCategoryForm(shouldCloseManager));
  if (isNewCategory(state)) {
    dispatch(deleteCategoryTemplate());
  }
};

export const categoriesManagerDiscardChanges = (shouldCloseManager = false) => (
  dispatch,
  getState,
) => {
  const state = getState();

  if (isPersistedCategory(state)) {
    const categoryId = getCategoryInEditId(state);
    dispatch(deleteCategory(categoryId));
  } else {
    const initialCopy = decorateCategoryWithType(getInitialCopy(state));
    dispatch(categoriesManagerUpdateCategoryLocally(initialCopy, true));
  }
  dispatch(userEventsDiscardChangesCategory({ isChanged: true }));
  dispatch(closeEditCategoryForm(shouldCloseManager));
  if (isNewCategory(state)) {
    dispatch(deleteCategoryTemplate());
  }
};

export const categoriesManagerSaveForumDataChanges = () => (dispatch, getState) => {
  const state = getState();
  const navigate = () => dispatch(categoriesManagerOpenMainWindow());

  if (isForumDataEdited(state)) {
    const forumData = getForumData(state);
    return dispatch(updateForumData(forumData))
      .then(forumData => categoriesManagerUpdateForumDataLocally(forumData, true))
      .then(navigate)
      .then(() => dispatch(showMessage(messages.MAIN_PAGE_UPDATED)));
  } else {
    return Promise.resolve(navigate());
  }
};

export const categoriesManagerCancelForumDataChanges = (shouldCloseManager = false) => (
  dispatch,
  getState,
) => {
  if (isForumDataEdited(getState())) {
    return dispatch(
      openModal(MODAL_TYPE_CATEGORIES_MANAGER_CONFIRM_DISCARD, {
        shouldCloseManager,
        isForumData: true,
      }),
    );
  }
  return dispatch(closeEditCategoryForm(shouldCloseManager));
};

export const categoriesManagerDiscardForumDataChanges = (shouldCloseManager = false) => (
  dispatch,
  getState,
) => {
  const initialCopy = getInitialCopy(getState());
  dispatch(categoriesManagerUpdateForumDataLocally(initialCopy, true));

  return dispatch(closeEditCategoryForm(shouldCloseManager));
};

export const categoriesManagerReorderCategories = (categories, movedItemIndex, method) => (
  dispatch,
  getState,
  { getRouter },
) => {
  const oldCategory = getCategory(getState(), categories[movedItemIndex]._id);
  dispatch(categoriesManagerShowLoader());
  dispatch(updateCategoryRanks(categories))
    .then(() => {
      const movedCategory = categories[movedItemIndex];
      return movedCategory && dispatch(fetchCategory(movedCategory._id));
    })
    .then(updatedCategory => {
      if (shouldReportReorderBi(method, updatedCategory.parentId, oldCategory.parentId)) {
        dispatch(
          userEventsMakeCategoryChildOrParent({
            method,
            oldCategory,
            updatedCategory,
          }),
        );
      }
      dispatch(categoriesManagerHideLoader());
    });
  dispatch(updateCategories(categories));
  const categoriesCount = getCategories(getState()).length;

  if (categoriesCount === 1 || categoriesCount === 2) {
    // if homepage changes, we need to trigger nav
    getRouter().match('/');
  }
};

function shouldReportReorderBi(method, newParentId, prevParentId) {
  const isChild = !!newParentId;
  const wasChild = !!prevParentId;

  return isChild !== wasChild || (isChild && wasChild && newParentId !== prevParentId);
}

export const categoriesManagerApi = {
  categoriesManagerCancelForumDataChanges,
  categoriesManagerCancelChanges,
  categoriesManagerEditCategory,
  categoriesManagerEditForumData,
  categoriesManagerCreateCategoryLocally,
  categoriesManagerReorderCategories,
  categoriesManagerSaveForumDataChanges,
  categoriesManagerPersistCategory,
  categoriesManagerSaveChanges,
  categoriesManagerUpdateForumDataLocally,
  categoriesManagerUpdateCategoryLocally,
};
