import createAction from '../../services/create-action';
import { loadTranslations } from '../../services/translations-getter';

export const FETCH_TRANSLATIONS_REQUEST = 'posts/FETCH_TRANSLATIONS_REQUEST';
export const FETCH_TRANSLATIONS_SUCCESS = 'posts/FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_FAILURE = 'posts/FETCH_TRANSLATIONS_FAILURE';
export const SET_TRANSLATIONS = 'posts/SET_TRANSLATIONS';

export const setTranslations = createAction(SET_TRANSLATIONS);
export const fetchTranslationsRequest = createAction(FETCH_TRANSLATIONS_REQUEST);
export const fetchTranslationsSuccess = createAction(FETCH_TRANSLATIONS_SUCCESS);
export const fetchTranslationsFailure = createAction(FETCH_TRANSLATIONS_FAILURE);

export function fetchTranslations(language) {
  return (dispatch, getState, { appParams }) => {
    dispatch(fetchTranslationsRequest());

    const translationsPromise = loadTranslations({
      baseUrl: appParams.baseUrls.translationsBaseUrl,
      translationsName: 'main',
      language,
    });

    return translationsPromise.then(
      body => dispatch(fetchTranslationsSuccess(body)),
      () => dispatch(fetchTranslationsFailure()),
    );
  };
}
