import uri from '@wix/communities-forum-client-commons/dist/src/services/urijs';
import { batchActions } from 'redux-batched-actions';
import { get } from 'lodash';
import createAction from '../../common/services/create-action';
import { buildPaginationRequestParams } from '@wix/communities-forum-client-commons/dist/src/services/pagination';
import { REPLIES_PER_PAGE } from '../constants/pagination';
import { FETCH_COMMENTS_SUCCESS, FETCH_COMMENTS_FAILURE } from './fetch-comments-constants';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { getPostBySlug } from '../selectors/post-selectors';
import { getHeader } from '@wix/communities-forum-client-commons/dist/src/services/get-header';

const FETCH_REPLIES = 'comments/FETCH_REPLIES';

export const fetchRepliesRequest = createAction(FETCH_REPLIES);
export const fetchRepliesSuccess = createAction(
  FETCH_COMMENTS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchRepliesFailure = createAction(FETCH_COMMENTS_FAILURE);

export const fetchReplies = ({
  postId,
  commentId,
  postSlug,
  postType,
  page,
  pageSize = REPLIES_PER_PAGE,
  pagination = null,
}) => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchRepliesRequest({ postId: postId || postSlug, postSlug }));

    const params = pagination || buildPaginationRequestParams(page, pageSize, 4);
    const promise = request(
      uri(`/posts/${postId || postSlug}/comments/${commentId}/replies`).query(params),
      {
        parseHeaders: true,
      },
    );
    return promise
      .then(({ body = [], headers = {} }) => {
        if (!postId) {
          postId = get(body[0], 'postId', get(getPostBySlug(postSlug), `_id`));
        }

        const payload = {
          comments: body,
          postId,
          postSlug,
          postType,
        };
        const meta = {
          page,
          paginationId: commentId,
          entityCount: parseInt(getHeader(headers, 'Wix-Forum-TotalResults'), 10),
        };
        return dispatch(fetchRepliesSuccess(payload, meta));
      })
      .catch(() => {
        dispatch(fetchRepliesFailure({ postSlug }));
      })
      .then(() => promise);
  };
};

export const fetchRepliesPromisified = createPromisifiedAction(fetchReplies, () => null);

export const fetchRepliesToPage = ({ postId, commentId, postSlug, page }) => {
  // this is used to load rest of replies up until given reply page. Initially we always fetch 1st page (5 replies), so offset is 5
  return fetchReplies({
    postId,
    commentId,
    postSlug,
    page,
    pagination: { offset: REPLIES_PER_PAGE, size: (page - 1) * REPLIES_PER_PAGE },
  });
};

export const fetchRepliesToPagePromisified = createPromisifiedAction(
  fetchRepliesToPage,
  () => null,
);

export const fetchInitialReplies = ({ postId, commentsIds = [], postSlug }) => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchRepliesRequest({ postId, postSlug }));
    const promise = request.post(uri(`/posts/${postId}/comments/replies`), commentsIds);
    return promise
      .then(result => {
        const actions = [];
        for (const { commentId, replies, totalResultCount } of result) {
          const payload = {
            comments: replies,
            postId,
            postSlug,
          };
          const meta = {
            page: 1,
            paginationId: commentId,
            entityCount: parseInt(totalResultCount, 10),
          };
          actions.push(fetchRepliesSuccess(payload, meta));
        }
        return dispatch(batchActions(actions));
      })
      .catch(() => dispatch(fetchRepliesFailure({ postSlug })))
      .then(() => promise);
  };
};
