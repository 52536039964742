import uri from '@wix/communities-forum-client-commons/dist/src/services/urijs';
import createAction from '../../services/create-action';
import { getCurrentInstance } from '../../services/get-instance';
import { get } from 'lodash';

export const SET_BASIC_PARAMS = 'SET_BASIC_PARAMS';
const setBasicParamsAction = createAction(SET_BASIC_PARAMS);

export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const setViewMode = createAction(SET_VIEW_MODE);

export const SET_INSTANCE = 'SET_INSTANCE';
export const setInstance = createAction(SET_INSTANCE);

export function setBasicParams({ language, pageNumber }) {
  return (dispatch, getState, { wixCodeApi, appParams, platformAPIs }) => {
    const showMobileView =
      wixCodeApi.location.url && uri(wixCodeApi.location.url).hasQuery('showMobileView', 'true');

    const apiBaseUrl =
      wixCodeApi.window.rendering.env === 'backend'
        ? appParams.baseUrls.apiBaseUrl
        : appParams.baseUrls.apiBaseUrlClient;

    const viewMode = wixCodeApi.window.viewMode.toLowerCase();
    let bsi;
    try {
      bsi = platformAPIs.biLoggerFactory().loggerClientFactory._defaults.bsi();
    } catch (error) {}
    return dispatch(
      setBasicParamsAction({
        date: Date.now(),
        viewMode,
        instance: getCurrentInstance(wixCodeApi),
        formFactor: showMobileView ? 'Mobile' : wixCodeApi.window.formFactor,
        isSSR: wixCodeApi.window.rendering.env === 'backend',
        isSeo: wixCodeApi.seo.isInSEO(),
        language,
        apiBaseUrl,
        isDebug: wixCodeApi.location.query.debug,
        isProduction: process.env.NODE_ENV === 'production',
        pageNumber,
        ricosBiParams: {
          visitorId: get(platformAPIs, 'bi.visitorId'),
          bsi,
          metaSiteId: get(platformAPIs, 'bi.metaSiteId'),
        },
      }),
    );
  };
}
