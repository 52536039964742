import { createPromisifiedAction } from '../../actions-promisifier/create-promisified-action';
import uri from '@wix/communities-forum-client-commons/dist/src/services/urijs';

/* wix has different media platforms.
requestCredentials - uses the internal wix platform, we use it for images
requestCredentialsV2Promisified - uses external wix platform, we use for file uploads
*/
export default function requestCredentials(request, type, params = {}) {
  return request(uri(`/media/credentials/${type}`).query({ ...params }));
}

export const requestCredentialsPromisified = createPromisifiedAction(
  (type, params) => (dispatch, getState, { request }) => {
    return requestCredentials(request, type, params);
  },
  c => c,
  e => e.status,
);

export const requestCredentialsV2Promisified = createPromisifiedAction(
  (fileName, mimeType) => (dispatch, getState, { request }) => {
    return request(`/media/v2/credentials?fileName=${fileName}&mimeType=${mimeType}`);
  },
  c => c,
  e => e.status,
);

export const requestFileDownloadUrlPromisified = createPromisifiedAction(
  path => (dispatch, getState, { request }) => {
    return request(`/media/v2/download-url?path=${path}`);
  },
  c => c,
  e => e.status,
);
